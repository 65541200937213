import moment from "moment";
import { business_email_validations } from "./RegexRules";
import { matches, userName } from "./RegexRules";

//  global Email Validations Function -----------------------------------!
export function emailValidation(e: any) {
  if (business_email_validations.test(e)) {
    return true;
  } else {
    return false;
  }
}

//  Global Password Validations -----------------------------------------!
export function passwordValidation(e: any) {
  if (matches.test(e)) {
    return true;
  } else {
    return false;
  }
}
export function userNameValidation(e: any) {
  if (userName.test(e)) {
    return true;
  } else {
    return false;
  }
}
// .withMessage('Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character.'),

export const numberFormat = (value: any) =>
  new Intl.NumberFormat("en-IN", {
    // style: "currency",
    currency: "INR",
  }).format(value);

export const formatString = (inputString: string) => {
  // Splitting the string based on underscore
  const splitString = inputString.split("_");

  // Capitalizing the first character of each word and joining them with space
  const result = splitString
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return result;
};

export const splitDateConversion = (ValueDate: any) => {
  if (ValueDate) {
    let parts = ValueDate.split("T");
    let finalDate = moment(parts[0]).format("DD/MM/YYYY");
    return finalDate;
  } else {
    return "N/A";
  }
};

export const lockingValidations = () => {
  // console.log();
  return "01/01/2024";
};
export const fontSizes = {
  xs: "10px",
  xsm: "12px",
  sm: "14px",
  md: "16px",
  lg: "18px",
  xlg: "20px",
  xxlg: "25px",
};
export const fontFamilys = {
  publicSans: "Public Sans",
};

export const removeSubstring = (value: String) => {
  let removeString;
  if (value) {
    removeString = value.substring(0, 20);
  } else {
    removeString = "N/A";
  }
  return removeString;
};

//  calculation Interest as per provided Bank Interest for Annual Bases.
export const calculateBankBalanceInterest = (value: any, paymentDays: any) => {
  let si =
    (parseInt(value ? value : "0") *
      6 *
      (parseInt(paymentDays ? paymentDays : "0") / 365)) /
    100;
  return si;
};
export const ReceivableAmtSum = (arr: any) => {
  // console.log(arr);
  if (arr.length <= 0) {
    return 0;
  } else {
    const TotalSum = arr.reduce((acc: any, obj: any) => {
      return acc + parseFloat(obj.amount);
    }, 0);
    return TotalSum;
  }
};
export const IndianTimeZoneFormat = (dateValue: any) => {
  const dtUTC = new Date(dateValue);
  const dtLocal = dtUTC.toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });
  const formattedTime = new Date(dtLocal).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });
  return formattedTime;
};

export function getCurrentTime() {
  const now = new Date();
  let hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  return `${hours}:${minutes} ${period}`;
}
export const formatedData = (value: string) => {
  const dateObject = new Date(value);
  const options: any = {
    // weekday: "long",
    year: "numeric",
    month: "short", // long
    day: "numeric",
  };
  const dateString = dateObject.toLocaleDateString("en-US", options);
  const timeString = dateObject.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  return `${dateString} (${dateObject.toLocaleDateString("en-US", {
    weekday: "short",
  })}), ${timeString}`;
  // };
  //   // const formattedDate = new Date(value).toLocaleString("en-US", {

  //   weekday: "long",
  //   year: "numeric",
  //   month: "long",
  //   day: "numeric",
  //   hour: "numeric",
  //   minute: "numeric",
  //   second: "numeric",
  //   hour12: true,
  // });
  // return formattedDate;
};
