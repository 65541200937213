import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  loginUser,
  logOutUser,
  onBoarding,
  saveUser,
} from "../../redux/slices/admin";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import SimpleLoader from "../../components/Loader";
import { CharacterLowercase } from "../../utils/characterCases/Cases";
import ApiService from "../../APIs/api";
import AdminLoginSvg from "../../assets/illustration_maintenance.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { VisibilityOff, Visibility } from "../../assets/icons/login";
import {
  Typography,
  Box,
  TextField,
  Stack,
  Button,
  InputAdornment,
  IconButton,
  Grid,
} from "@mui/material";
import { fontFamilys } from "../../utils/Validations/ValidationsFn";

export default function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:400px)");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [emailErrorMsg, setEmailErrorMsg] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleOnChangeEmail = (e: any) => {
    setEmailErrorMsg(false);
    setUsername(e.target.value);
  };

  const handleOnChangePassword = (e: any) => {
    setPasswordErrorMsg(false);
    setPassword(e.target.value);
  };

  const handleFormSubmitLogin = async (e: any) => {
    e.preventDefault();
    setBtnDisabled(true);
    setOpen(true);

    const body = {
      username: CharacterLowercase(username),
      password: password,
      request: "Admin02",
    };
    try {
      const responseData: any = await ApiService.post("/auth/login", body);
      if (responseData.status === 200 && responseData.data?.result) {
        dispatch(loginUser(responseData.data.result));
        dispatch(saveUser(responseData.data.result));
        setOpen(false);
        toast("Welcome to Two-step Verification !", {
          style: {
            color: "green",
            fontFamily: "Public Sans",
            fontWeight: 500,
          },
        });
        navigate("verification");
      } else {
        setOpen(false);
        setPasswordErrorMsg(true);
        setBtnDisabled(false);
        console.error("Error:", responseData);
      }
    } catch (error) {
      setOpen(false);
      setPasswordErrorMsg(true);
      setBtnDisabled(false);
      console.error("API Error:", error);
    }
  };

  useEffect(() => {
    if (username && password) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [username, password]);

  useEffect(() => {
    dispatch(logOutUser({}));
    dispatch(onBoarding({}));
  }, []);

  return (
    <Box
      // margin={2}
      justifyContent={"center"}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      alignItems={"center"}
    >
      <Grid container spacing={isMobile ? 2 : 6}>
        <Grid item xs={12} md={6} display={"flex"} justifyContent={"end"}>
          <Box
            boxShadow={
              "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 4px 0px rgba(145, 158, 171, 0.20)"
            }
            sx={{
              borderRadius: 4,
              px: 6,
              pt: 4,
              pb: 1,
              marginTop: isMobile ? 4 : 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "340px",
            }}
          >
            <Stack spacing={2} sx={{ mb: 3, position: "relative" }}>
              <Typography
                variant="h5"
                component="div"
                fontSize={22}
                fontWeight={700}
                fontFamily={"Public Sans"}
                lineHeight={"30px"}
                color={"#334A67"}
              >
                Sign into your Dashboard
              </Typography>

              <Box
                component="form"
                onSubmit={handleFormSubmitLogin}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  error={emailErrorMsg}
                  helperText={emailErrorMsg ? "Email Invalid Address" : ""}
                  fullWidth
                  name="username"
                  label="Username"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "#919EAB",
                      fontSize: "14px",
                      fontFamily: fontFamilys.publicSans,
                      fontWeight: "500",
                      mt: 0.2,
                    },
                  }}
                  id="username"
                  onChange={handleOnChangeEmail}
                  type="text"
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      height: "40px",
                      borderColor: "#919EAB",
                    },
                  }}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Enter Your Password"
                  id="password"
                  error={passwordErrorMsg}
                  autoComplete="current-password"
                  helperText={
                    passwordErrorMsg ? "Wrong Password, Please try again !" : ""
                  }
                  type={showPassword ? "text" : "password"}
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "#919EAB",
                      fontSize: "14px",
                      fontFamily: fontFamilys.publicSans,
                      fontWeight: "500",
                      mt: 0.2,
                    },
                  }}
                  size="small"
                  onChange={handleOnChangePassword}
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      height: "40px",
                      borderColor: "#919EAB",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  disabled={btnDisabled}
                  size="small"
                  color="inherit"
                  sx={{
                    my: 3,
                    mb: "8px",
                    py: 0.7,
                    borderRadius: 2,
                    textTransform: "none",
                  }}
                  style={{
                    fontSize: 14,
                    backgroundColor: btnDisabled
                      ? "rgba(145, 158, 171, 0.24)"
                      : "#303981",
                    fontFamily: "Public Sans",
                    fontWeight: "700",
                    color: btnDisabled
                      ? "var(--action-light-disabled, rgba(145, 158, 171, 0.80))"
                      : "white",
                  }}
                  fullWidth
                  variant="contained"
                  type="submit"
                >
                  Login
                </Button>
                <SimpleLoader props={open} />
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <Link to="/forgotpassword" style={{ textDecoration: "none" }}>
                    <Typography
                      fontWeight={400}
                      marginTop={1}
                      fontSize={14}
                      fontFamily={"Public Sans"}
                      color={"#334A67"}
                    >
                      Forgot Password?
                    </Typography>
                  </Link>
                </div>
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <img
              src={AdminLoginSvg}
              style={{
                maxHeight: "450px",
                maxWidth: "400px",
                alignItems: "center",
                marginTop: isMobile ? "20px" : "80px",
                height: "auto",
                width: isMobile ? "50%" : "100%", // Adjusted width based on screen size
              }}
              alt=""
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
