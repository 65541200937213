//  General Mixing - As per reusable code and structure for ui / code / feature

import dayjs from "dayjs";

// UI -
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

// Export Elements -
export { MenuProps };

export const formatDateString = (dateString: string): string => {
  const date = new Date(dateString);

  // Extract year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
// Calculate maxDate as 30 days before the selected EndDate
export const calculateMaxDate = (date: any) => {
  if (date) {
    return date.subtract(30, "day");
  }
  return dayjs(); // Default to today's date if no EndDate is set
};

export const reportIdentifiers = {
  accounting: "accounting",
  revenue: "revenue",
  batch: "batch",
};

export const userDocusStatus = {
  UnderReview: "Under_Review",
  approved: "Approved",
  rejected: "Rejected",
};
export const documentStatus = {
  new: "New",
  approved: "Approved",
  rejected: "Rejected",
};
