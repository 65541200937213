import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import { Router } from "./routes";
import { Toaster } from "react-hot-toast";
import LoginPage from "./pages/auth/LoginPage";
import NotAuthorised from "./components/NotAuthorised";
import TwoStepVerification from "./pages/auth/TwoStepAuthentication";
import { useProSidebar } from "react-pro-sidebar";
import ForgotPassword from "./pages/auth/ForgotPassword";
import { useSelector } from "react-redux";
import { AppBar, Box } from "@mui/material";
import Sidebars from "./components/Sidebar/Sidebars";
import Footer from "./components/Footer";

export default function App() {
  const collapsed = useProSidebar().collapsed;
  const token = useSelector((state: any) => state.user?.userLogin);

  // Forgot Password Router
  const ForgotPassowrdRouter = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          overflowX: "hidden", // Prevents horizontal scrolling
          width: "100vw", // Full viewport width
        }}
      >
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              backgroundColor: "rgba(145, 158, 171, 0.08)",
              overflowX: "hidden", // Avoids any horizontal movement within the main content
            }}
          >
            <AppBar
              style={{
                backgroundColor: "white",
                boxShadow:
                  "0px 6px 12px -4px rgba(145, 158, 171, 0.12), 0px 0px 0px 0px rgba(145, 158, 171, 0.20)",
              }}
            >
              <Toaster />
              <Header />
            </AppBar>
            <Box
              sx={{
                minHeight: "calc(100vh - 100px)", // Adjusted height
                paddingTop: 5,
                overflowX: "hidden", // Ensures the content does not cause horizontal scroll
              }}
            >
              <ForgotPassword />
            </Box>
            <Footer />
          </Box>
        </Box>
      </Box>
    );
  };

  // Dashboard Router with Sidebar and Protected Routes
  const DashboardRouter = () => {
    if (token !== undefined && token !== "") {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Box sx={{ display: "flex", flexGrow: 1 }}>
            <Sidebars />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                // padding: 2,
                backgroundColor: "rgba(145, 158, 171, 0.08)",
              }}
            >
              <AppBar
                style={{
                  backgroundColor: "white",
                  boxShadow:
                    "0px 6px 12px -4px rgba(145, 158, 171, 0.12), 0px 0px 0px 0px rgba(145, 158, 171, 0.20)",
                }}
              >
                <Toaster />
                <Header collapsed={collapsed} />
              </AppBar>
              <Box
                sx={{
                  minHeight: "calc(100vh - 100px)", // 64px accounts for AppBar height
                  // padding: 2,
                  paddingTop: 5,
                }}
              >
                <Router />
              </Box>

              <Footer />
            </Box>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Toaster />
          <Header />
          <Box component="main" sx={{ flexGrow: 1 }}>
            <NotAuthorised />
          </Box>
          <Footer />
        </Box>
      );
    }
  };

  // Login Router
  const LoginRouter = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          overflowX: "hidden", // Prevents horizontal scrolling
          width: "100vw", // Full viewport width
        }}
      >
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              backgroundColor: "rgba(145, 158, 171, 0.08)",
              overflowX: "hidden", // Avoids any horizontal movement within the main content
            }}
          >
            <AppBar
              style={{
                backgroundColor: "white",
                boxShadow:
                  "0px 6px 12px -4px rgba(145, 158, 171, 0.12), 0px 0px 0px 0px rgba(145, 158, 171, 0.20)",
              }}
            >
              <Toaster />
              <Header />
            </AppBar>
            <Box
              sx={{
                minHeight: "calc(100vh - 100px)", // Adjusted height
                paddingTop: 5,
                overflowX: "hidden", // Ensures the content does not cause horizontal scroll
              }}
            >
              <LoginPage />
            </Box>
            <Footer />
          </Box>
        </Box>
      </Box>
    );
  };

  // Two-Step Verification Router
  const TwoStepVerificationRouter = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          overflowX: "hidden", // Prevents horizontal scrolling
          width: "100vw", // Full viewport width
        }}
      >
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              backgroundColor: "rgba(145, 158, 171, 0.08)",
              overflowX: "hidden", // Avoids any horizontal movement within the main content
            }}
          >
            <AppBar
              style={{
                backgroundColor: "white",
                boxShadow:
                  "0px 6px 12px -4px rgba(145, 158, 171, 0.12), 0px 0px 0px 0px rgba(145, 158, 171, 0.20)",
              }}
            >
              <Toaster />
              <Header />
            </AppBar>
            <Box
              sx={{
                minHeight: "calc(100vh - 100px)", // Adjusted height
                paddingTop: 5,
                overflowX: "hidden", // Ensures the content does not cause horizontal scroll
              }}
            >
              <TwoStepVerification />
            </Box>
            <Box
              sx={{
                bottom: 0,
                width: "100%",
                backgroundColor: "#F7F9FB",
                textAlign: "center",
                paddingTop: "5px",
                paddingBottom: "10px",
                pr: "-20px",
                position: "fixed !important",

                boxShadow: "0px -2px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Footer />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Routes>
      <Route path="/" element={LoginRouter()} />
      <Route path="/verification" element={TwoStepVerificationRouter()} />
      <Route path="/forgotpassword" element={ForgotPassowrdRouter()} />
      <Route path="/app/*" element={DashboardRouter()}></Route>
    </Routes>
  );
}
