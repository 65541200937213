// // ----------------------------------------------------------------------
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  error: null,
  userLogin: <any>[],
  user: {
    _id: "",
    id: "",
    gstLimit: "",
    aadharLimit: "",
    panLimit: "",
    enrollmentFees: "",
    cin: "",
    termsOfService: "",
    privacyPolicy: "",
    disclaimer: "",
  },
  BusinessKycDetails: <any>[],
  login: false,
  userData: <any>{},
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.login = true;
    },
    loginUser: (state, action) => {
      state.isLoading = true;
      // console.log(action.payload, "new changes added ", action.payload);
      state.userLogin = action.payload;
    },
    logOutUser: (state, action) => {
      state.isLoading = true;
      state.login = false;
      state.userLogin = action.payload;
    },
    saveUser: (state, action) => {
      state.isLoading = true;
      state.userData = action.payload;
    },
    onBoarding: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    },
    onBoardingUserLimits: (state, action) => {
      state.isLoading = false;
      state.user.gstLimit = action.payload.gstLimit;
      state.user.aadharLimit = action.payload.aadharLimit;
      state.user.cin = action.payload.cin;
      state.user.panLimit = action.payload.panLimit;
    },
    onBoardingTermsPolicy: (state, action) => {
      state.isLoading = false;
      state.user.termsOfService = action.payload.termsOfService;
      state.user.privacyPolicy = action.payload.privacyPolicy;
      state.user.disclaimer = action.payload.disclaimer;
    },
    onBoardingEnrollmentFees: (state, action) => {
      state.isLoading = false;
      state.user.enrollmentFees = action.payload.enrollmentFees;
    },
    AllUsersBusinessDetails: (state, action) => {
      state.isLoading = true;
      state.BusinessKycDetails = action.payload;
    },
  },
});
export const {
  loginUser,
  logOutUser,
  onBoarding,
  onBoardingUserLimits,
  onBoardingTermsPolicy,
  onBoardingEnrollmentFees,
  login,
  saveUser,
} = userSlice.actions;

export default userSlice.reducer;
