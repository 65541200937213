// import { CircularProgress, Typography } from "@mui/material";
// import { Box } from "@mui/system";

// const CircularTableLoader = () => {
//   return (
//     <Box
//       display={"flex"}
//       flexDirection={"column"}
//       justifyContent={"center"}
//       alignItems={"center"}
//       textAlign={"center"}
//       my={4}
//       width="100%"
//     >
//       <CircularProgress color="inherit" />
//       <Typography fontSize={14} color={"#757575"} mt={2}>
//         Please wait, records are fetching...
//       </Typography>
//     </Box>
//   );
// };

// export default CircularTableLoader;

// 2nd loader
import { Box, Typography } from "@mui/material";

const CircularTableLoader = () => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      textAlign={"center"}
      my={4}
      width="100%"
    >
      {/* Fancy loader */}
      <Box
        sx={{
          width: "50px",
          height: "50px",
          border: "4px solid #e0e0e0", // Light border color
          borderTop: "4px solid #303981", // Darker color for the rotating part
          borderRadius: "50%",
          animation: "spin 1s linear infinite",
        }}
      ></Box>

      {/* Loading text */}
      <Typography fontSize={14} color={"#757575"} mt={2}>
        Please wait, Records are fetching...
      </Typography>

      {/* Keyframe animation */}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </Box>
  );
};

export default CircularTableLoader;

// 3rd loader

// import { Box, Typography } from "@mui/material";

// const CircularTableLoader = () => {
//   return (
//     <Box
//       display="flex"
//       flexDirection="column"
//       justifyContent="center"
//       alignItems="center"
//       textAlign="center"
//       my={4}
//       width="100%"
//     >
//       {/* Central Dot */}
//       <Box
//         sx={{
//           width: "10px",
//           height: "10px",
//           backgroundColor: "#3498db",
//           borderRadius: "50%",
//           position: "relative",
//         }}
//       >
//         {/* Orbiting Dots */}
//         <Box
//           sx={{
//             position: "absolute",
//             top: "-20px",
//             left: "50%",
//             width: "8px",
//             height: "8px",
//             backgroundColor: "#F58336",
//             borderRadius: "50%",
//             animation: "orbit 1.2s linear infinite",
//             transformOrigin: "0 30px",
//           }}
//         />
//         <Box
//           sx={{
//             position: "absolute",
//             top: "-10px",
//             left: "50%",
//             width: "8px",
//             height: "8px",
//             backgroundColor: "#F58336",
//             borderRadius: "50%",
//             animation: "orbit 1.2s linear infinite",
//             animationDelay: "-0.2s",
//             transformOrigin: "0 30px",
//           }}
//         />
//         <Box
//           sx={{
//             position: "absolute",
//             top: "10px",
//             left: "50%",
//             width: "8px",
//             height: "8px",
//             backgroundColor: "#F58336",
//             borderRadius: "50%",
//             animation: "orbit 1.2s linear infinite",
//             animationDelay: "-0.4s",
//             transformOrigin: "0 30px",
//           }}
//         />
//       </Box>

//       {/* Loading Text */}
//       <Typography fontSize={14} color={"#757575"} mt={2}>
//         Please wait, records are fetching...
//       </Typography>

//       {/* Keyframe Animation */}
//       <style>
//         {`
//           @keyframes orbit {
//             0% {
//               transform: rotate(0deg) scale(1);
//             }
//             50% {
//               transform: rotate(180deg) scale(0.5);
//             }
//             100% {
//               transform: rotate(360deg) scale(1);
//             }
//           }
//         `}
//       </style>
//     </Box>
//   );
// };

// export default CircularTableLoader;

// // 4rth loader
// import { Box, Typography } from "@mui/material";

// const CircularTableLoader = () => {
//   return (
//     <Box
//       display="flex"
//       flexDirection="column"
//       justifyContent="center"
//       alignItems="center"
//       textAlign="center"
//       my={4}
//       width="100%"
//     >
//       {/* Central Dot */}
//       <Box
//         sx={{
//           width: "12px",
//           height: "12px",
//           backgroundColor: "#303981", // Central dot color
//           borderRadius: "50%",
//           position: "relative",
//         }}
//       >
//         {/* Orbiting Dots */}
//         <Box
//           sx={{
//             position: "absolute",
//             top: "-30px",
//             left: "50%",
//             width: "8px",
//             height: "8px",
//             backgroundColor: "#F58336", // Orbiting dots color
//             borderRadius: "50%",
//             animation: "orbit 1.5s linear infinite",
//             transformOrigin: "0 30px",
//           }}
//         />
//         <Box
//           sx={{
//             position: "absolute",
//             top: "30px",
//             left: "50%",
//             width: "8px",
//             height: "8px",
//             backgroundColor: "#F58336",
//             borderRadius: "50%",
//             animation: "orbit 1.5s linear infinite",
//             animationDelay: "-0.5s",
//             transformOrigin: "0 -30px",
//           }}
//         />
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "-20px",
//             width: "8px",
//             height: "8px",
//             backgroundColor: "#F58336",
//             borderRadius: "50%",
//             animation: "orbit 1.5s linear infinite",
//             animationDelay: "-1s",
//             transformOrigin: "30px 0",
//           }}
//         />
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             right: "-20px",
//             width: "8px",
//             height: "8px",
//             backgroundColor: "#F58336",
//             borderRadius: "50%",
//             animation: "orbit 1.5s linear infinite",
//             animationDelay: "-1.5s",
//             transformOrigin: "-30px 0",
//           }}
//         />
//       </Box>

//       {/* Loading Text */}
//       <Typography fontSize={14} color={"#303981"} mt={2}>
//         Please wait, records are fetching...
//       </Typography>

//       {/* Keyframe Animation */}
//       <style>
//         {`
//           @keyframes orbit {
//             0% {
//               transform: rotate(0deg);
//             }
//             100% {
//               transform: rotate(360deg);
//             }
//           }
//         `}
//       </style>
//     </Box>
//   );
// };

// export default CircularTableLoader;
